import { FormattedMessage } from 'react-intl';
import MainLayout from 'components/MainLayout';
import messages from './messages';
import './help.css';

export default function Help() {

  const supportLink = () => (
    <a
      target="_blank"
      rel="noreferrer"
      href="mailto:support-geomaps@husqvarnagroup.com"
    >
      support-geomaps@husqvarnagroup.com
    </a>
  );

  /*
    I'd prefer we not use a specific css file. Use tailwind classes as much as possible. The benefit is that
    if we change colors, spacings, etc it will be reflected automatically.
  */
  return (
    <MainLayout>
      <h2 className="pb-base">
        <FormattedMessage {...messages.qaTitle} />
      </h2>
      <div className="list pb-sm">
        <h4><FormattedMessage {...messages.qaQ1} /></h4>
        <div><FormattedMessage {...messages.qaA1} /></div>

        <h4><FormattedMessage {...messages.qaQ2} /></h4>
        <div>
          <FormattedMessage {...messages.qaA2} values={{
            bullet: text => <div className="bullet">{text}</div>,
            img: (src) => <img src={src[0]?.toString()} />,
            imgHalf: (src) => <img className="half" src={src[0]?.toString()} />,
            imgWide: (src) => <img className="wide" src={src[0]?.toString()} />
          }} />
        </div>

        <h4><FormattedMessage {...messages.qaQ3} /></h4>
        <div>
          <FormattedMessage {...messages.qaA3} values={{
            bullet: text => <div className="bullet">{text}</div>,
            img: (src) => <img className="tree" src={src[0]?.toString()} />
          }} />
        </div>

        <h4><FormattedMessage {...messages.qaQ4} /></h4>
        <div>
          <FormattedMessage {...messages.qaA4} />
        </div>

        <h4><FormattedMessage {...messages.qaQ5} /></h4>
        <div>
          <FormattedMessage {...messages.qaA5} />
        </div>

        <h4><FormattedMessage {...messages.faqQ1} /></h4>
        <div>
          <FormattedMessage
            {...messages.faqA1}
            values={{
              a: supportLink
            }}
          />
          </div>
          <h4><FormattedMessage {...messages.faqQ2} /></h4>
          <div>
            <FormattedMessage
              {...messages.faqA2}
              values={{
                a: supportLink
              }}
            />
          </div>
      </div>

      <h2 className="pb-base">
        <FormattedMessage {...messages.textTitle} />
      </h2>
      <p className="pb-sm">

        <FormattedMessage
          {...messages.textGeneral}
          values={{
            a: supportLink
          }}
        />
      </p>
    </MainLayout>
  );
}
