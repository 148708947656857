import { defineMessages } from 'react-intl';

export default defineMessages({
  textTitle: {
    id: 'Help.text.title',
    defaultMessage: 'Help'
  },
  textGeneral: {
    id: 'Help.text.general',
    defaultMessage: 'If you need help or have suggestions please contact the GeoMaps team at <a>email</a>.'
  },
  qaTitle: {
    id: 'Help.qa.title',
    defaultMessage: 'FAQ'
  },
  qaQ1: {
    id: 'Help.qa.q1',
    defaultMessage: 'What kinds of forest risk can GeoMaps monitor?'
  },
  qaA1: {
    id: 'Help.qa.a1',
    defaultMessage: 'The GeoMaps forest monitoring application shall cover majority of forest risk like pest attack, windfall, multi-damage etc. In current release, we monitor bark beetle attack in your forest stand.'
  },
  qaQ2: {
    id: 'Help.qa.q2',
    defaultMessage: 'What type of bark beetle detection does GeoMaps provide?'
  },
  qaA2: {
    id: 'Help.qa.a2',
    defaultMessage: 'GeoMaps offer two types of detections as follows:<bullet>Previous Years - Bark Beetle History</bullet><bullet>Current Season - Bark Beetle Monitor</bullet><imgHalf>qa2-1.png</imgHalf><imgHalf>qa2-2.jpg</imgHalf><imgHalf>qa2-3.jpg</imgHalf><imgWide>qa2-4.png</imgWide>'
  },
  qaQ3: {
    id: 'Help.qa.q3',
    defaultMessage: 'How could I understand the different color (stages) in bark beetle attack?'
  },
  qaA3: {
    id: 'Help.qa.a3',
    defaultMessage: 'In both Previous Years and Current Session Bark Beetle Detection, GeoMaps provides three stage detection based on tree crown defoliation:<bullet>Early stage detection</bullet><bullet>Visible stage detection</bullet><bullet>Late stage detection</bullet><img>qa3-1.png</img>'
  },
  qaQ4: {
    id: 'Help.qa.q4',
    defaultMessage: 'What level of accuracy can GeoMaps provides?'
  },
  qaA4: {
    id: 'Help.qa.a4',
    defaultMessage: 'Our AI model can achieve 80% accuracy for Previous Years Detection. For Current Season Detection, The accuracy is a bit less due to many factors, which may potentially lead to `False positive` bark beetle attacks detections.'
  },
  qaQ5: {
    id: 'Help.qa.q5',
    defaultMessage: 'How often the monitoring status can be updated?'
  },
  qaA5: {
    id: 'Help.qa.a5',
    defaultMessage: 'Normally every 1-4 weeks, depends on imagery quality. Once the new analysis is done, you will have notification by email or SMS.'
  },
  faqTitle: {
    id: 'Help.faq.title',
    defaultMessage: 'FAQ'
  },
  faqQ1: {
    id: 'Help.faq.q1',
    defaultMessage: 'I don’t see my property. I only see the message "Your property is being analyzed".'
  },
  faqA1: {
    id: 'Help.faq.a1',
    defaultMessage: 'It takes 1-2 business days after adding your property for the property to appear on your account. If the property map does not appear on your account, please email us at <a>email</a>.'
  },
  faqQ2: {
    id: 'Help.faq.q2',
    defaultMessage: 'I would like my information deleted.'
  },
  faqA2: {
    id: 'Help.faq.a2',
    defaultMessage: 'Please email us at <a>email</a> and we will take the necessary steps to delete your account and the associated data.'
  }
});
