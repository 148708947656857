import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import messages from './messages';
import MenuItem from './MenuItem';
import { ReactComponent as Staccato } from 'assets/images/staccato.svg';
import { ReactComponent as HusqvarnaLogo } from 'assets/images/logo-husqvarna.svg';
import { ReactComponent as HusqvarnaSmallLogo } from 'assets/images/logo-husqvarna-small.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/log-out.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info-outlined.svg';
import { ReactComponent as ProfileIcon} from 'assets/icons/avatar-outlined.svg';
import { ReactComponent as SatelliteIcon} from 'assets/icons/satellite.svg';
import { useSignOut } from './queries';
import { useUser } from 'components/queries';

export default function Navbar() {
  const intl = useIntl();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const { data: user } = useUser();

  const handleInfo = () => {
    navigate('/info');
  };

  const handleProfile = () => {
    navigate('/profile');
  };

  const handleSignOut = () => {
    signOut.refetch();
    navigate('/');
  };

  return (
    <div className="w-full" role="menu">
      {/* White line top */}
      <div className="h-sm bg-white dark:bg-gray-700" />

      {/* Navbar content */}
      <div className="flex flex-row justify-between h-[56px] bg-white dark:bg-gray-700">

        <div className="flex pl-sm">
          {/* App name */}
          <div className="fill-brand-100 w-lg h-lg mr-sm">
            <SatelliteIcon />
          </div>
          <div className="pr-2xlg font-bold">
            <div className="text-[9px] leading-[12px] text-gray-400 uppercase">Husqvarna</div>
            <h3 className="text-gray-600 leading-h5 uppercase dark:text-gray-200">GeoMaps</h3>
            <div className="text-sm leading-[12px] text-brand-100 uppercase">BETA</div>
          </div>

          {/* Primary actions */}
          <div className="flex h-full pt-xsm" role="navigation">
            <div className="flex flex-row gap-[40px] h-full text-gray-600 text-[14px] leading-[14px] uppercase" role="menuitem">
              <MenuItem path="/demo" label={intl.formatMessage(messages.labelDemo)} />
              <MenuItem path="/barkbeetle" label={intl.formatMessage(messages.labelBarkBeetle)} />
              <MenuItem path="/help" label={intl.formatMessage(messages.labelHelp)} />
            </div>
          </div>
        </div>

        {/* Secondary actions */}
        <div className="flex flex-row items-center bg-gray-200 dark:bg-gray-800">
          <Staccato className="fill-white dark:fill-gray-700" />
          <div className="flex flex-row items-center pl-xsm pr-lg gap-base tablet:mobile:pr-sm tablet:mobile:gap-sm">
            <div className="flex hover:cursor-pointer" onClick={handleProfile}>
              <ProfileIcon
                title={intl.formatMessage(messages.labelProfile)}
                className="w-[24px] h-[24px] fill-gray-600 dark:fill-gray-200 cursor-pointer"
              />
              <p className="pl-xxsm leading-h5">
                {`${user?.payload.first_name} ${user?.payload.last_name.slice(0,1)}.`}
              </p>
            </div>
            <InfoIcon
              title={intl.formatMessage(messages.labelInfo)}
              className="w-[20px] h-[20px] fill-gray-600 dark:fill-gray-200 cursor-pointer"
              onClick={handleInfo}
            />
            <LogoutIcon
              title={intl.formatMessage(messages.labelSignOut)}
              className="w-[24px] h-[24px] fill-gray-600 dark:fill-gray-200 cursor-pointer"
              onClick={handleSignOut}
            />
            <HusqvarnaLogo
              title="Husqvarna"
              className="w-[138px] h-[25px] fill-gray-400 dark:fill-gray-500 pl-sm laptop:desktop:large:flex mobile:tablet:hidden"
            />
            <HusqvarnaSmallLogo
              title="Husqvarna"
              className="w-[35px] h-[42px] fill-gray-400 dark:fill-gray-500 pl-sm mobile:tablet:flex laptop:desktop:large:hidden"
            />
          </div>
        </div>
      </div>

      {/* Gray line bottom */}
      <div className="h-xsm bg-gray-200 dark:bg-gray-800" />
    </div>
  );
}
